<template>
  <div class="dashboard">
	<Menu title="PSO Total"></Menu>

	<div class="container">
		<template v-if="isMounted">
			<template v-if="isLoading">
				<skeleton />
			</template>
			<template v-else>
				<div class="dashboard__section">
					<filters :custom-class="periodClasses" name="Period" :items="datePeriod" @select="getPeriodFilters" />
<!-- <template v-if="isLoading">-->
<!-- <div class="mt-32">-->
<!-- <PuSkeleton height="20px" color="#E0E0E0"></PuSkeleton>-->
<!-- </div>-->
<!-- <div class="mt-16">-->
<!-- <PuSkeleton height="20px" color="#E0E0E0"></PuSkeleton>-->
<!-- </div>-->
<!-- <div class="mt-16">-->
<!-- <PuSkeleton height="20px" color="#E0E0E0"></PuSkeleton>-->
<!-- </div>-->
<!-- <div class="mt-16">-->
<!-- <PuSkeleton height="20px" color="#E0E0E0"></PuSkeleton>-->
<!-- </div>-->
<!-- </template>-->
				<template v-if="response">
					<Table class="dashboard__table" v-bind="tableOptions">
						<template #percent="{ item, value, col: { name } }">
							<template v-if="isActiveGoal(value) && item[name].enabled">
								<Tooltip trigger="clickToToggle">
									<template slot="reference">
										<div class="goal" :class="item[name].enabled ? `--${getGoalClass(value)}` : '--none'">{{
											value
										}}%
										</div>
									</template>

									<template>
										<div>Target: {{ value }} %</div>
				<!-- <div v-if="target(item[name])">Target: {{ Math.round(target(item[name])) }} %</div>-->
				<!-- <div v-if="name === 'availability'">{{ passed(item[name]) }} of {{ total(item[name]) }} products-->
				<!-- ({{ value }}%) in stock-->
				<!-- </div>-->
				<!-- <div v-if="name === 'reviews'">{{ passed(item[name]) }} of {{ total(item[name]) }} products-->
				<!-- ({{ value }}%) had above 21 reviews-->
				<!-- </div>-->
				<!-- <div v-if="name === 'ratings'">{{ passed(item[name]) }} of {{ total(item[name]) }} products-->
				<!-- ({{ value }}%) had rating above 4.0-->
				<!-- </div>-->
				<!-- <div v-if="name === 'image'">{{ passed(item[name]) }} of {{ total(item[name]) }} products-->
				<!-- ({{ value }}%) had correct image-->
				<!-- </div>-->
									</template>
								</Tooltip>
							</template>
							<template v-else>
								<div class="goal --none">No data</div>
							</template>
						</template>
					</Table>
				</template>

				<template v-else>Sorry, no data</template>

				</div>
			</template>
		</template>

		<div class="empty-filter" v-else>Пожалуйста, выберите значения фильтра</div>
	</div>
</div>

</template>

<script>
import Menu from '@/components/Menu/Menu.vue'
import Table from '@/components/Table/Table.vue'
import Skeleton from '@/components/Nestle/Skeleton/Common.vue'
import Tooltip from '@/components/Elements/Tooltip.vue'
import Filters from '@/components/Nestle/GlobalFilter/Filter.vue'

export default {
	name: 'MainPage',
	components: {
		Menu,
		Table,
		Skeleton,
		Tooltip,
		Filters,
	},
	props: {
		needUpdateResult: {
			type: Boolean,
			default: false,
		},
		stores: {
			type: String,
			default: '',
		},
		categories: {
			type: String,
			default: '',
		},
		brands: {
			type: String,
			default: '',
		},
		regions: {
			type: String,
			default: '',
		},
		dateFrom: {
			type: String,
			default: '',
		},
		dateTo: {
			type: String,
			default: '',
		},
		isFilterFull: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			goalsBelowCheckbox: {
				high: true,
				medium: true,
				light: true,
			},
			goalsAboveCheckbox: {
				light: true,
				medium: true,
				high: true,
			},
			products: null,
      tableData: [
          { id: 1, name: 'ПСО', store: { value: null, enabled: false }, category: { value: null, enabled: false }, brand: { value: null, enabled: false }},
          { id: 2, name: 'Поиск', store: { value: null, enabled: false }, category: { value: null, enabled: false }, brand: { value: null, enabled: false }},
          { id: 3, name: 'Контент', store: { value: null, enabled: false }, category: { value: null, enabled: false }, brand: { value: null, enabled: false }},
          { id: 4, name: 'Рейтинги', store: { value: null, enabled: false }, category: { value: null, enabled: false }, brand: { value: null, enabled: false }},
          { id: 5, name: 'Стоки', store: { value: null, enabled: false }, category: { value: null, enabled: false }, brand: { value: null, enabled: false }},
      ],
			filters: [
				{
					value: 1,
					title: 'Stories',
					group: 'retailers',
				},
				{
					value: 2,
					title: 'Categories',
					group: 'categories',
				},
				{
					value: 3,
					title: 'Brands',
					group: 'brands',
				}
			],
			response: null,
			isLoading: true,
			isMounted: false,
      datePeriod: {
        name: 'period',
        values: [
          {id: 1, name: '13.03.2023 - 19.03.2023', checked: false},
          {id: 2, name: '20.03.2023 - 26.03.2023', checked: false},
          {id: 3, name: '27.03.2023 - 02.04.2023', checked: false}
        ]
      }
		}
	},
	computed: {
		tableColumns() {
			return [
				{
					title: '',
					width: 100,
					value: ({ name }) => name,
					enabled: () => {},
				},
				{
					title: 'Store',
					name: 'store',
					width: 110,
					slot: 'percent',
          value: ({ store }) => store.value,
          enabled: ({ enabled }) => enabled,
				},
				{
					title: 'Category',
					name: 'category',
					width: 110,
					slot: 'percent',
          value: ({ category }) => category.value,
          enabled: ({ enabled }) => enabled,
				},
				{
					title: 'Brand',
					name: 'brand',
					width: 110,
					slot: 'percent',
          value: ({ brand }) => brand.value,
          enabled: ({ enabled }) => enabled,
				},
			]
		},
		tableOptions() {
			return {
				columns: this.tableColumns,
				data: this.tableData,
			}
		},
		periodClasses() {
			return {
				period: 'period',
			}
		}
	},
	methods: {
		getPeriodFilters() {
		// console.log(data); //TODO: заглушка, пока не реализован рабочий функционал на бэке
		},

		async fetch() {
			this.isMounted = true;

			const businessUnitString = this.tableData.map(el => el.name).join(',')

			const params = {
				stores: this.stores,
				categories: this.categories,
				brands: this.brands,
				regions: this.regions,
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				businessUnit: businessUnitString,
			};

			try {
				this.isLoading = true

				const result = await this.$api.unilever.getPSOTotalData(params);
				if (result?.items.length) {
					this.response = true;	
					this.setTableData(result.items);
				} else {
					this.response = false;
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.isLoading = false
			}

		},

		changeFilter(item) {
			this.activeFilter = item
			this.fetch()
		},

		getGoalClass(val) {
      // const result = val / target * 100
			let className
			if (val < 101 && val >= 80) {
				className = 'above-high'
			} else if (val >= 66 && val <= 79.99) {
				className = 'above-medium'
			} else if (val >= 50 && val <= 65.99) {
				className = 'above-light'
			} else if (val >= 33 && val <= 49.99) {
				className = 'below-light'
			} else if (val >= 16 && val <= 32.99) {
				className = 'below-medium'
			} else {
				className = 'below-high'
			}
			return className
		},

		isActiveGoal(val) {
			let params = this.getGoalClass(val).split('-')
			return params[0] === 'below' ? this.goalsBelowCheckbox[params[1]] : this.goalsAboveCheckbox[params[1]]
		},

		changeHandler({item, type}) {
			if (type === 'below') {
				this.goalsBelowCheckbox[item.title] = !item.value
			} else {
				this.goalsAboveCheckbox[item.title] = !item.value
			}
		},

		setTableData(data) {
			if (!data.length) return;

			data.forEach((el, index) => {
				for (let key in el) {
					if (key in this.tableData[index] && key !== 'name') {
						this.tableData[index][key].value = el[key];
						this.tableData[index][key].enabled = !!el[key];
					}
				}
			});
		},
	},

	watch: {
		needUpdateResult: {
			async handler() {
				await this.fetch()
			},
		},
		current: {
			handler() {
				this.fetch()
			},
		},
	},

}
</script>

<style lang="scss" scoped>
.dashboard {
	&__nav {
		width: 190px;
	}
	&__date {
		width: 100px;
		color: color(white);
		cursor: pointer;
	}
	&__tabs {
		margin: 36px 0;
	}
	&__subtitle {
		font-size: 16px;
		font-weight: 600;
	}
	&__section {
		padding: 20px;
		background-color: color(white);
		border-radius: 4px;
	}
	&__filters {
		margin-top: 20px;
	}

	&__filter {
		padding: 4px 8px;
		border: 1px solid color(gray-300);
		cursor: pointer;

		&.--active {
			background-color: color(gray-200);
		}
	}

  .additional-link {
    color: color(white);
    margin-right: 15px;
  }

	&__table {
		margin-top: 10px;

		tbody {
			tr {
				&:first-of-type {
					height: 80px;
				}
			}
		}
	}

	.goal {
		height: 40px;
		justify-content: center;
		font-weight: 600;
    display: flex;
    align-items: center;
    border-radius: 5px;

		&.--none {
			background-color: color(gray-200);
			color: color(gray-700);
			cursor: not-allowed;
		}

		&.--above-high {
			background-color: color(goal-above-high);
		}
		&.--above-medium {
			background-color: color(goal-above-medium);
		}
		&.--above-light {
			background-color: color(goal-above-light);
		}
		&.--below-light {
			background-color: color(goal-below-light);
		}
		&.--below-medium {
			background-color: color(goal-below-medium);
		}
		&.--below-high {
			background-color: color(goal-below-high);
		}
	}
}
.empty-filter {
	font-size: 16px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

</style>
